<ng-container *ngIf="navigationHeaderData$ | async as navigationHeaderData">
  <nav
    class="grid-layout grid-cols-2 right-heavy align-center"
    [ngClass]="navigationHeaderData.theme.class"
  >
    <ul>
      <li><a
          [routerLink]="['/']"
          [routerLinkActive]="'is-active'"
          class="logo"
        ><shared-q90-logo /></a></li>
    </ul>
  </nav>
</ng-container>