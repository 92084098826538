import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  inject,
  Input,
  Output,
  EventEmitter,
  SecurityContext,
} from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { AvatarComponent } from '../avatar/avatar.component';
import { CurrentTenantIdStore } from '../../../pages/tenant/tenant/tenant.service';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { FormInputFileComponent } from '../../../shared/forms/components/control/form-input-file/form-input-file.component';
import { FormInputComponent } from '../../../shared/forms/components/control/form-input/form-input.component';
import { GetAsFormControlPipe } from '../../../shared/forms/pipes/get-as-form-control.pipe';
import { GetFormControlPipe } from '../../../shared/forms/pipes/get-form-control.pipe';
import { TenantProfile } from '../../../entity/tenant/models/tenant-profile';
import { Q90ResponseData } from '../../../shared/interfaces/q90-response';
import { MediaService } from '../../services/media.service';
import { CurrentContentChannelIdStore } from '../../../pages/content-channel/content-channel/content-channel.service';
import { ModalComponent } from '../../../shared/components/modal/modal.component';
import { ErrorMessagesStoreService } from '../../../shared/stores/error-messages-store.service';
import { Q90Response } from '../../../shared/models/q90-response';

interface FormAvatarData {
  loadingPreview: boolean;
  previewing: boolean;
}

@Component({
  selector: 'media-upload',
  standalone: true,
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GetFormControlPipe,
    GetAsFormControlPipe,
    AvatarComponent,
    SpinnerComponent,
    FormInputComponent,
    ModalComponent,
  ],
})
export class MediaUploadComponent extends FormInputFileComponent {
  protected formBuilder = inject(FormBuilder);
  private currentTenantIdStore = inject(CurrentTenantIdStore);
  private currentContentChannelIdStore = inject(CurrentContentChannelIdStore);
  private mediaService = inject(MediaService);
  public errorMessagesStore = inject(ErrorMessagesStoreService);

  private uploadingStore = new BehaviorSubject<boolean>(false);
  uploading$: Observable<boolean> = this.uploadingStore.asObservable();

  private uploadSelectedStore = new BehaviorSubject<boolean>(false);
  uploadSelected$: Observable<boolean> =
    this.uploadSelectedStore.asObservable();

  public media!: File;
  public mediaUrl!: string;

  private sanitizer = inject(DomSanitizer);

  formAvatarData$!: Observable<FormAvatarData>;

  private loadingPreviewStore = new BehaviorSubject<boolean>(false);
  loadingPreview$: Observable<boolean> =
    this.loadingPreviewStore.asObservable();

  private previewingStore = new BehaviorSubject<boolean>(false);
  previewing$: Observable<boolean> = this.previewingStore.asObservable();

  previewUrl: string = '';

  @Input() mediaTypeId: number = 0;
  @Input() entityType: string = 'tenant';

  @Output() mediaSelected = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  formGroupMedia = this.formBuilder.group({
    mediaUpload: this.formBuilder.group({
      uploadFiles: this.formBuilder.control<string>(''),
    }),
  });

  override ngOnInit(): void {
    super.ngOnInit();
    this.formAvatarData$ = combineLatest([
      this.loadingPreview$,
      this.previewing$,
    ]).pipe(
      map(([loadingPreview, previewing]) => {
        return {
          loadingPreview,
          previewing,
        };
      }),
    );
  }

  override onUploadsInputChange(e: Event): void {
    let id = '';
    switch (this.entityType) {
      case 'tenant':
        id = this.currentTenantIdStore.getTenantId();
        break;
      case 'contentchannel':
        id = this.currentContentChannelIdStore.getContentChannelId();
        break;
      case 'screenshot':
        id = '';
        break;
    }
    const mediaUploadControls = this.formGroupMedia.get(
      'mediaUpload',
    ) as FormGroup;

    super.onUploadsInputChange(e);
    this.previewingStore.next(true);
    this.media = this.uploads[0] as File;

    if (mediaUploadControls.valid) {
      this.uploadingStore.next(true);
      const formData = new FormData();
      formData.append('uploadFiles', this.uploads[0], this.uploads[0].name);
      id ? this.uploadMedia(id, formData) : this.uploadScreenshot(formData);
    }
  }

  uploadMedia(id: string, formData: FormData) {
    this.mediaService
      .uploadMedia(this.entityType, id, this.mediaTypeId, formData)
      .subscribe({
        next: (response: Q90ResponseData<never>) => {
          this.uploadingStore.next(false);
          response.statusCode === 200
            ? this.uploadSuccess(response)
            : this.uploadFailed(response);
        },
        error: (error) => {
          this.uploadError(error);
        },
      });
  }
  uploadScreenshot(formData: FormData) {
    this.mediaService.uploadScreenshot(formData).subscribe({
      next: (response: Q90ResponseData<never>) => {
        this.uploadingStore.next(false);
        response.statusCode === 200
          ? this.uploadSuccess(response)
          : this.uploadFailed(response);
      },
      error: (error) => {
        this.uploadError(error);
      },
    });
  }

  uploadSuccess(response: Q90ResponseData<never>) {
    this.formGroupMedia
      .get('mediaUpload.uploadFiles')
      ?.updateValueAndValidity();
    const reader = new FileReader();
    reader.onloadstart = () => {
      this.loadingPreviewStore.next(true);
    };
    reader.onload = (evt) => {
      const boo = evt.target?.result;
      this.mediaUrl = this.sanitizer.sanitize(
        SecurityContext.RESOURCE_URL,
        this.sanitizer.bypassSecurityTrustResourceUrl(boo as string),
      ) as string;
    };
    reader.onloadend = () => {
      this.loadingPreviewStore.next(false);
    };
    reader.readAsDataURL(this.media);

    this.formGroupMedia.reset();
    this.control.patchValue(this.getVal(response.data));
  }
  uploadFailed(response: Q90ResponseData<never>) {
    this.formGroupMedia.reset();
    this.previewingStore.next(false);
    this.errorMessagesStore.setErrorMessage(
      new Q90Response().deserialize(response),
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadError(error: any) {
    this.formGroupMedia.controls['mediaUpload'].controls[
      'uploadFiles'
    ].patchValue('');
    this.previewingStore.next(false);
    this.uploadingStore.next(false);
    this.uploadSelectedStore.next(false);
    this.errorMessagesStore.setErrorMessage(error);
  }

  override removeFile(e: Event): void {
    super.removeFile(e);
    this.control.patchValue('');
    this.previewingStore.next(false);
  }

  getVal(data: TenantProfile) {
    switch (this.mediaTypeId) {
      case 0:
        return {
          url: data.url,
          id: data.id,
        };
      case 1:
        return data.styleGuide;
      case 2:
        return data.logoLightBackground;
      case 3:
        return data.logoDarkBackground;
      case 4:
        return data.logoVideoImage;
      case 5:
        return data.icon;
      default:
        return null;
    }
  }

  openPreview($event: MouseEvent) {
    $event.preventDefault();
    this.previewUrl = this.control.value.url || this.control.value;
  }
}
