<ng-container [formGroup]="formGroup">
  <div
    class="form-control {{styleClass}} {{additionalStyleClasses}}"
    [ngClass]="{'hide-errors-hints':(!showErrorHints)}"
  >
    <label
      class="wrap-lines"
      for="{{id}}"
      [ngClass]="{'visually-hidden': !showLabel, 'disabled': isDisabled}"
    >{{label}}</label>
    <textarea
      id="{{id}}"
      [formControl]="control | getAsFormControl"
      [attr.placeholder]="showPlaceholder ? (placeholder + '&#8230;') : null"
    ></textarea>
    <div
      class="submit-warning"
      [ngClass]="{'invalid-after-submit': submitted && control.invalid}"
    ><span class='mci-warning'></span></div>
  </div>
  <div
    class="form-errors {{styleClass}}"
    *ngIf="showErrorHints && control.invalid && (submitted || control.touched)"
  >
    <p>{{getErrorMessage()}}</p>
  </div>
</ng-container>