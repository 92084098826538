import { Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GeneralErrorStore {
  generalError: WritableSignal<string> = signal('');
  generalErrors: WritableSignal<string[]> = signal([]);

  addError(error: string) {
    const errors = this.generalErrors();
    errors.push(error);
    this.generalErrors.set(errors);
    this.generalError.set((Math.random() + 1).toString(36).substring(7));
  }
  removeError(index: number) {
    const errors = this.generalErrors();
    errors.splice(index, 1);
    this.generalErrors.set(errors);
    this.generalError.set((Math.random() + 1).toString(36).substring(7));
  }
}
