<ng-container *ngIf="applicationData$ | async as applicationData">
  <div
    id="q90-body-header"
    [ngClass]="applicationData.theme.class"
  >
    <div class="grid-layout grid-cols-2 spread align-center">
      <div><core-navigation-header></core-navigation-header></div>
      <div class="grid-layout grid-cols-2 left-heavy spread align-center">
        <core-site-search />
        <account-user-account-navigation />
      </div>
    </div>
  </div>
  <div
    id="q90"
    class="layout-main"
    [ngClass]="{'sidebar-collapsed': applicationData.appMenuCollapsedState}"
  >
    <div
      id="q90-sidebar"
      [ngClass]="applicationData.theme.class"
    >
      <button
        class="collapsed-toggle"
        (click)="toggleCollapse()"
      > {{applicationData.appMenuCollapsedState ? '⯈' : '⯇'}} </button>
      <div
        id="q90-sidebar-header"
        [ngClass]="applicationData.theme.class"
      >
      </div>
      <div
        id="q90-sidebar-nav"
        [ngClass]="applicationData.theme.class"
      >
        <core-navigation-sidebar />
        <account-user-account-menu-tenants *ngIf="applicationData.userAccountTenantMenu" />

        <core-navigation-footer />
      </div>
    </div>
    <div
      id="q90-body"
      [ngClass]="[applicationData.theme.class, (applicationData.appMenuState?'is-open':'is-closed')]"
    >
      <main [ngClass]="applicationData.theme.class">
        <div id="q90-body-wrapper">
          <router-outlet></router-outlet>
        </div>
      </main>
    </div>
    <core-cookie-disclaimer *ngIf="!cookieDisclaimerAgreed" />
    <core-refreshing-token />
  </div>
  <shared-modal *ngIf="applicationData.unsavedFormsWarning">
    <div class="grid-layout grid-cols2 align-center spread">
      <h5>Warning</h5>
      <div class="button-bar">
        <button type="button">Abort</button>
        <button type="button">Continue</button>
      </div>
    </div>
    You have unsaved forms on the page. Would you like to continue with form or abort changes?
  </shared-modal>
</ng-container>