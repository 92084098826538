import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, map, catchError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationStoreService } from '../../authentication/stores/authentication-store.service';
import { DestroyService } from '../../shared/services/destroy.service';
import { Media } from '../models/media';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private authenticationStore = inject(AuthenticationStoreService);
  private http = inject(HttpClient);
  protected destroyed = inject(DestroyService);

  protected mediaStore = new BehaviorSubject<Media[]>([]);
  mediaStore$: Observable<Media[]> = this.mediaStore.asObservable();

  uploadMedia(
    entityType: string,
    id: string,
    mediaTypeId: number,
    formData: FormData,
  ) {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot}${environment.apiEndpointVersion}/${entityType}/${id}/mediatype/${mediaTypeId}/upload`,
        formData,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw err;
        }),
      );
  }
  uploadScreenshot(formData: FormData) {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot}${environment.apiEndpointVersion}/feedback/attachment/upload`,
        formData,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw err;
        }),
      );
  }
}
