import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map, catchError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { TenantInterface } from '../../../../entity/tenant/models/tenant';
import {
  Q90ResponseData,
  Q90SearchResult,
} from '../../../../shared/interfaces/q90-response';
import { GeneralErrorStore } from '../../../../stores/general-error-store.service';
import { ErrorService } from '../../../../shared/services/error.service';
import { AuthenticationStoreService } from '../../../../authentication/stores/authentication-store.service';

export interface FeedbackQuery {
  attachment?: string[] | null;
  bugs_1?: string | null;
  bugs_2?: string | null;
  compatability_1?: string | null;
  compatability_2?: string | null;
  design_1?: string | null;
  design_2?: string | null;
  functionality_1?: string | null;
  functionality_2?: string | null;
  functionality_3?: string | null;
  general_1?: string | null;
  general_2?: string | null;
  id: string;
  other?: string | null;
  performance_1?: string | null;
  performance_2?: string | null;
  performance_3?: string | null;
  userFriendlyness_1?: string | null;
  userFriendlyness_2?: string | null;
  userFriendlyness_3?: string | null;
}

@Injectable({ providedIn: 'root' })
export class FeedbackService {
  generalErrorStore = inject(GeneralErrorStore);
  private authenticationStore = inject(AuthenticationStoreService);
  private http = inject(HttpClient);
  private errorService = inject(ErrorService);

  sendFeedback(
    query: FeedbackQuery,
  ): Observable<Q90ResponseData<Q90SearchResult<TenantInterface[]>>> {
    return this.http
      .post<Q90ResponseData<Q90SearchResult<TenantInterface[]>>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/feedback/add`,
        {
          ...query,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }
}
