import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlComponent } from '../form-control.component';
import { ReactiveFormsModule } from '@angular/forms';
import { GetAsFormControlPipe } from '../../../pipes/get-as-form-control.pipe';

@Component({
  selector: 'shared-form-range-slider',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, GetAsFormControlPipe],
  templateUrl: './form-range-slider.component.html',
  styleUrls: ['./form-range-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormRangeSliderComponent extends FormControlComponent {
  @Input() steps = 10;
  @Input() firstStep = '0';
  @Input() lastStep = this.steps.toString();

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }
}
