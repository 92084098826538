<ng-container [formGroup]="formGroup">
  <div
    class="form-control {{ styleClass }} auto-height"
    [ngClass]="{ 'hide-errors-hints': !showErrorHints }"
  >
    <label
      for="{{ id }}"
      [ngClass]="{ 'visually-hidden': !showLabel }"
    >{{
      label
      }}</label>
    <div class="align-center q90-control-padding">
      @if (control.value.url !== '' && control.value) {
      <div class="py-05">
        <button
          class="picture-preview-button"
          (click)="openPreview($event)"
        >
          <picture class="picture-preview">
            <source media="(min-width: 1536px)" />
            <img
              [src]="control.value.url ? control.value.url : control.value"
              [alt]="control.value.url ? control.value.url : control.value"
            />
          </picture>
        </button>
        <button
          type="button"
          class="textual"
          (click)="removeFile($event)"
        >
          <span class="nav-icon mci-close color-danger"></span>Delete image
        </button>
      </div>
      } @else if (previewing$ | async) {
      <div class="grid-layout grid-cols-1 align-center py-05">
        @if (loadingPreview$ | async) {
        <shared-spinner />
        }
      </div>
      } @else {
      <div class="media-upload-select py-05">
        <span class="nav-icon mci-upload"></span>Upload an attachment
        <input
          class="media-upload"
          accept="image/png, image/jpeg"
          id="{{ label }}"
          type="file"
          (change)="onUploadsInputChange($event)"
          [formControl]="
              formGroupMedia | getFormControl: 'mediaUpload.uploadFiles'
            "
        />
      </div>
      }
    </div>
    <div
      class="submit-warning"
      [ngClass]="{ 'invalid-after-submit': submitted && control.invalid }"
    >
      <span class="mci-warning"></span>
    </div>
  </div>
  <div
    class="form-errors {{ styleClass }}"
    *ngIf="showErrorHints && control.invalid && (submitted || control.touched)"
  >
    <p>{{ getErrorMessage() }}</p>
  </div>
  <shared-modal
    *ngIf="previewUrl"
    (closeModal)="previewUrl = ''"
  >
    <div class="image-preview">
      <picture>
        <img
          [src]="previewUrl"
          alt=""
        />
      </picture>
    </div>
  </shared-modal>
</ng-container>