<div class="grid-layout grid-cols-2 fill-screen-height">
  <section class="q90-section home-text">
    <h1>Quatro90 Beta</h1>
    <h5>Welkom bij Quatro 90,</h5>
    <p>Quatro 90 is ontworpen om jouw werk efficiënter, eenvoudiger en intuïtiever te maken. Voordat we Quatro 90
      volledig
      lanceren, willen we zeker weten dat alles perfect werkt en aan de verwachtingen voldoet. Daarom nodigen we jou uit
      om
      deel te nemen aan deze testfase.
      <br /><br />
      In deze testomgeving kun je kennismaken met de verschillende functies van Quatro 90. In deze eerste fase zal dat
      de
      profiel omgeving en de monitoring module zijn. Feedback geven over jouw ervaring zal ons helpen om Quatro 90 te
      optimaliseren. Je krijgt de kans om verbeteringen voor te stellen, bugs te rapporteren en jouw waardevolle
      inzichten te
      delen.
      <br /><br />
      <b>
        Waarom jouw bijdrage belangrijk is:
      </b>
    </p>
    <ul>
      <li>
        <b>
          Gebruiksvriendelijkheid verbeteren:
        </b>
        Help ons ervoor te zorgen dat Quatro 90 intuïtief en prettig werkt.
      </li>
      <li>
        <b>
          Functionaliteit testen:
        </b>
        Controleer of alles naar behoren functioneert en waar mogelijk nog beter kan.
      </li>
      <li>
        <b>
          Betrouwbaarheid verhogen:
        </b>
        Jouw feedback helpt ons eventuele foutjes op te sporen en te corrigeren.
      </li>
    </ul>
    <p>
      <b>
        Wat kun je verwachten?
      </b>
    </p>
    <ul>
      <li>
        Toegang tot de verschillende modules in Quatro 90.
      </li>
      <li>
        Een eenvoudig feedbackformulier waarmee je jouw ervaringen kunt delen.
      </li>
    </ul>
    <p>
      We waarderen jouw tijd en inzet enorm. Samen kunnen we ervoor zorgen dat Quatro 90 klaar is voor een succesvolle
      lancering!
      <br /><br />
      Heb je vragen of loop je tegen iets aan? Vul dat het feedback formulier in.
      <br /><br />
      Alvast bedankt voor jouw hulp,<br />
      <b>
        Het Quatro 90 Team
      </b>
    </p>

  </section>
  <section class="q90-section p-0">
    <app-feedback />
  </section>
</div>