import { Deserializable } from 'src/app/shared/interfaces/deserializable';

export interface TenantProfileInterface {
  chamberOfCommerceNumber: string;
  colophonTitle: string;
  countryId: string;
  description: string;
  entity: string;
  entityName: string;
  icon: string;
  languageId: string;
  logoLightBackground: string;
  logoDarkBackground: string;
  logoVideoImage: string;
  url: string;
  id: string;
  poweredByEligible: boolean;
  relationId: string;
  showInColophon: boolean;
  styleGuide: string;
  tenantId: string;
  type: string;
  vatnumber: string;
}

export type TenantProfileKeys = keyof TenantProfileInterface;

export class TenantProfile implements Deserializable {
  constructor(
    private _entityName: string = '',
    private _type: string = '',
    private _description: string = '',
    private _chamberOfCommerceNumber: string = '',
    private _entity: string = '',
    private _relationId: string = '',
    private _vatnumber: string = '',
    private _languageId: string = '',
    private _countryId: string = '',
    private _tenantId: string = '',
    private _poweredByEligible: boolean = false,
    private _showInColophon: boolean = false,
    private _colophonTitle: string = '',
    private _logoLightBackground: string = '',
    private _logoDarkBackground: string = '',
    private _logoVideoImage: string = '',
    private _icon: string = '',
    private _styleGuide: string = '',
    private _defaultLanguage: number = 0,
    private _url: string = '',
    private _id: string = '',
  ) {}

  // boo(): TenantProfileInterface {
  //   const b = JSON.stringify(this);
  //   return JSON.parse(b) as TenantProfileInterface;
  // }

  get tenantId() {
    return this._tenantId;
  }
  set tenantId(tenantId) {
    this._tenantId = tenantId;
  }
  get description() {
    return this._description;
  }
  set description(description) {
    this._description = description;
  }
  get relationId() {
    return this._relationId;
  }
  set relationId(relationId) {
    this._relationId = relationId;
  }
  get entityName() {
    return this._entityName;
  }
  set entityName(entityName) {
    this._entityName = entityName;
  }
  get poweredByEligible() {
    return this._poweredByEligible;
  }
  set poweredByEligible(poweredByEligible) {
    this._poweredByEligible = poweredByEligible;
  }
  get showInColophon() {
    return this._showInColophon;
  }
  set showInColophon(showInColophon) {
    this._showInColophon = showInColophon;
  }
  get colophonTitle() {
    return this._colophonTitle;
  }
  set colophonTitle(colophonTitle) {
    this._colophonTitle = colophonTitle;
  }
  get logoLightBackground() {
    return this._logoLightBackground;
  }
  set logoLightBackground(logoLightBackground) {
    this._logoLightBackground = logoLightBackground;
  }
  get logoDarkBackground() {
    return this._logoDarkBackground;
  }
  set logoDarkBackground(logoDarkBackground) {
    this._logoDarkBackground = logoDarkBackground;
  }
  get url() {
    return this._url;
  }
  set url(url) {
    this._url = url;
  }
  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
  }
  get logoVideoImage() {
    return this._logoVideoImage;
  }
  set logoVideoImage(logoVideoImage) {
    this._logoVideoImage = logoVideoImage;
  }
  get icon() {
    return this._icon;
  }
  set icon(icon) {
    this._icon = icon;
  }
  get styleGuide() {
    return this._styleGuide;
  }
  set styleGuide(styleGuide) {
    this._styleGuide = styleGuide;
  }
  get defaultLanguage() {
    return this._defaultLanguage;
  }
  set defaultLanguage(defaultLanguage) {
    this._defaultLanguage = defaultLanguage;
  }
  get chamberOfCommerceNumber() {
    return this._chamberOfCommerceNumber;
  }
  set chamberOfCommerceNumber(chamberOfCommerceNumber) {
    this._chamberOfCommerceNumber = chamberOfCommerceNumber;
  }
  get vatnumber() {
    return this._vatnumber;
  }
  set vatnumber(vatnumber) {
    this._vatnumber = vatnumber;
  }
  get type() {
    return this._type;
  }
  set type(type) {
    this._type = type;
  }
  get entity() {
    return this._entity;
  }
  set entity(entity) {
    this._entity = entity;
  }
  get languageId() {
    return this._languageId;
  }
  set languageId(languageId) {
    this._languageId = languageId;
  }
  get countryId() {
    return this._countryId;
  }
  set countryId(countryId) {
    this._countryId = countryId;
  }

  deserialize(input: TenantProfileInterface): this {
    Object.assign(this, input);
    return this;
  }
}
