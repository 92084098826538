<ng-container *ngIf="applicationData$ | async as applicationData">
  <div
    id="q90"
    class="layout-no-sidebar"
  >
    <div
      id="q90-header"
      [ngClass]="applicationData.theme.class"
    >
      <div
        id="q90-header-primary"
        class="grid-layout no-gap grid-cols-2 spread align-center"
        [ngClass]="applicationData.theme.class"
      >
        <core-navigation-header></core-navigation-header>
      </div>
    </div>
    <div
      id="q90-body"
      [ngClass]="applicationData.theme.class"
    >
      <main>
        <div
          id="q90-body-wrapper"
          [ngClass]="applicationData.theme.class"
        >
          <router-outlet></router-outlet>
        </div>
      </main>
    </div>
    <core-refreshing-token />
  </div>
</ng-container>