<section
  *ngIf="feedbackFormData$ | async as feedbackFormData; else spinnerFull"
  class="q90-section p-0"
>
  <form
    modeClass
    [formGroup]="formGroup"
    (ngSubmit)="sendFeedback()"
  >
    <header>
      <div class="grid-layout grid-cols-2 spread left-heavy q90-divider align-center">
        <h4>Feedback</h4>
        <div class="button-bar">
          <shared-form-submit
            [pristine]="formGroup.pristine"
            [submitting]="feedbackFormData.submitting"
            [buttonLabel]="'send'"
          ></shared-form-submit>
        </div>
      </div>
    </header>
    <div class="q90-section p-0 animation-container">
      <section
        #choices
        *ngIf="activeSection() === ''"
        class="q90-section py-0 feedback-card"
      >
        <h4>Welke functies heb je getest?</h4>
        <div class="button-bar align-left mb-1">
          <button
            type="button"
            *ngFor="let section of sections"
            [ngClass]="{'action':filterGroup.get(section.field)?.value===true}"
            (click)="chooseSection(section.field)"
          >{{section.title}}</button>
        </div>
      </section>

      <section
        #functionality
        *ngIf="activeSection() === 'functionality'"
        class="feedback-card"
      >
        <h4 class="px-1">Functionaliteit</h4>
        <shared-form-textarea
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Werkten alle functies zoals verwacht? Zo niet, welke niet en wat ging er mis?'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.functionality_1'"
        />
        <shared-form-textarea
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Waren er functies die je nuttig vond? Waarom?'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.functionality_2'"
        />
        <shared-form-textarea
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Ontbreken er volgens jou belangrijke functies? Zo ja, welke?'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.functionality_3'"
        />
      </section>
      <section
        #performance
        *ngIf="activeSection() === 'performance'"
        class="feedback-card"
      >
        <h4 class="px-1">Prestaties</h4>
        <shared-form-textarea
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Heb je vertragingen, crashes of andere prestatieproblemen ervaren? Zo ja, kun je deze beschrijven?'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.performance_1'"
        />
        <shared-form-range-slider
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Hoe snel reageerde de modules bij het uitvoeren van acties? (Schaal: Zeer traag – Zeer snel)'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.performance_2'"
          [firstStep]="'Zeer traag'"
          [lastStep]="'Zeer snel'"
        />

        <shared-form-range-slider
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Hoe stabiel vond je de modules tijdens het gebruik? (Schaal van 1-10)'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.performance_3'"
        />
      </section>
      <section
        #userFriendlyness
        *ngIf="activeSection() === 'userFriendlyness'"
        class="feedback-card"
      >
        <h4 class="px-1">Gebruiksvriendelijkheid</h4>
        <shared-form-textarea
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Waren er onderdelen van de interface die verwarrend waren? Zo ja, welke?'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.userFriendlyness_1'"
        />
        <shared-form-range-slider
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Hoe duidelijk vond je de foutmeldingen of andere meldingen (Schaal van 1-10)'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.userFriendlyness_2'"
        />
        <shared-form-textarea
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Was de navigatie binnen de web omgeving logisch en overzichtelijk? (Ja/Nee, toelichting)'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.userFriendlyness_3'"
        />
      </section>
      <section
        #compatability
        *ngIf="activeSection() === 'compatability'"
        class="feedback-card"
      >
        <h4 class="px-1">Compatibiliteit</h4>
        <shared-form-textarea
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Op welk apparaat en besturingssysteem heb je Q90 getest? (Open vraag)'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.compatability_1'"
        />
        <shared-form-textarea
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Heb je compatibiliteitsproblemen ervaren met jouw apparaat of OS? Zo ja, welke?'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.compatability_2'"
        />
      </section>
      <section
        #design
        *ngIf="activeSection() === 'design'"
        class="feedback-card"
      >
        <h4 class="px-1">Design en Esthetiek </h4>
        <shared-form-range-slider
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Hoe beoordeel je het visuele ontwerp van de software? (Schaal van 1-10)'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.design_1'"
        />
        <shared-form-textarea
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Zijn er onderdelen van het ontwerp die je bijzonder goed of juist storend vond? Zo ja, welke?'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.design_2'"
        />
      </section>
      <section
        #bugs
        *ngIf="activeSection() === 'bugs'"
        class="feedback-card"
      >
        <h4 class="px-1">Problemen en Bugs</h4>
        <shared-form-textarea
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Heb je bugs gevonden? Zo ja, kun je deze beschrijven (incl. stappen om te reproduceren)?'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.bugs_1'"
        />
        <shared-form-textarea
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Heb je onverwachte resultaten of gedragingen ervaren? Zo ja, wat gebeurde er?'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.bugs_2'"
        />
      </section>
      <section
        #general
        *ngIf="activeSection() === 'general'"
        class="feedback-card"
      >
        <h4 class="px-1">Algemene Feedback</h4>
        <shared-form-textarea
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Wat vond je het meest positief aan de software?'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.general_1'"
        />
        <shared-form-textarea
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Wat zou je veranderen of verbeteren?'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.general_2'"
        />
      </section>
      <section
        #other
        *ngIf="activeSection() === 'other'"
        class="feedback-card"
      >
        <h4 class="px-1">Overige</h4>
        <shared-form-textarea
          [styleClass]="'form-control-q90'"
          [submitted]="submitted"
          [label]="'Heb je verder nog opmerkingen of suggesties?'"
          [formGroup]="formGroup"
          [control]="formGroup | getFormControl: 'feedbackAreas.other'"
        />
      </section>

      <div
        *ngFor="let block of attachments.controls; let i = index"
        class="media-block"
      >
        <media-upload
          [styleClass]="'form-control-q90'"
          [label]="'Screenshot'"
          [submitted]="submitted"
          [formGroup]="formGroup"
          [control]="block"
          [entityType]="'screenshot'"
        />
        <button
          class="delete-media is-transparent"
          *ngIf="i > 0"
          type="button"
          (click)="deleteAttachment(i)"
        ><span class="nav-icon mci-close color-danger"></span></button>
      </div>
      <div class="button-bar p-1 pt-0">
        <button
          type="button"
          (click)="addAttachment()"
        >+</button>
      </div>

      <shared-error-messages [errorMessagesStore]="errorMessagesStore" />
      <div
        class="button-bar p-1"
        *ngIf="activeSection() !== ''"
      >
        <button
          type="button"
          (click)="back()"
        >back</button>
        <button
          [disabled]="formGroup.pristine"
          (click)="completeSection(activeSection())"
          class="action"
          type="button"
        >next</button>
      </div>
    </div>
  </form>
</section>
<ng-template #spinnerFull>
  <section class="q90-section no-bg grid-colspan-12 centered">
    <shared-spinner [styleClass]="'spinner-green-lg'" />
  </section>
</ng-template>