import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, takeUntil, Observable, forkJoin, map, catchError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApplicationEvent } from '../../core/services/app.service';
import { PrimeableService } from '../../core/services/primeable.service';
import {
  ContentChannelInterface,
  ContentChannel,
} from '../../entity/content-channel/models/content-channel';
import { TeamInterface, Team } from '../../entity/team/models/team';
import { TenantInterface, Tenant } from '../../entity/tenant/models/tenant';
import {
  UserAccountInterface,
  UserAccount,
} from '../../entity/user/models/user-account';
import {
  Q90ResponseData,
  Q90SearchResult,
} from '../../shared/interfaces/q90-response';
import { Person, PersonInterface } from '../../entity/person/models/person';
import { Q90Response } from '../../shared/models/q90-response';
import { Q90EntitySearchQuery } from '../../entity/interfaces/entity-search';

@Injectable({
  providedIn: 'root',
})
export class UserAccountService extends PrimeableService {
  constructor() {
    super();
    this.appService.applicationEvent$
      .pipe(
        filter((event) => event !== ApplicationEvent.Idle),
        takeUntil(this.destroyed),
      )
      .subscribe((setting) => {
        this.appService.debug(
          `${this.constructor.name}: application event: ${setting}`,
        );
        if (setting === ApplicationEvent.UserAccountUpdate) {
          this.refresh();
        }
      });
  }

  protected prime() {}

  protected authenticated(): void {
    this.primeUserAccountStores();
  }

  protected refresh(): void {
    this.primeUserAccountStores();
  }

  protected logout(): void {
    this.userAccountStore.clearStore();
    this.authenticationStore.clearStore();
  }

  private primeUserAccountStores() {
    this.setQueryingPrimeableService(true);
    const observablesArray: Observable<
      | Q90ResponseData<UserAccountInterface>
      | Q90ResponseData<boolean>
      | Q90ResponseData<TenantInterface[]>
      | Q90ResponseData<ContentChannelInterface[]>
      | Q90ResponseData<TeamInterface[]>
    >[] = [];
    observablesArray.push(this.userAccount());
    observablesArray.push(this.isLoggedIn());
    observablesArray.push(this.myTenants());
    observablesArray.push(this.myContentChannels());
    observablesArray.push(this.myTeams());
    const combinedObservable$ = forkJoin([...observablesArray]);
    combinedObservable$.pipe(takeUntil(this.destroyed)).subscribe({
      next: (response: any) => {
        this.setQueryingPrimeableService(false);

        const userAccountData =
          response[0] as Q90ResponseData<UserAccountInterface>;
        const userObject: UserAccount = new UserAccount().deserialize(
          <UserAccountInterface>userAccountData.data,
        );
        this.userAccountStore.authenticatedUserAccountStore.setStore(
          userObject,
        );

        const isLoggedInData = response[1] as Q90ResponseData<boolean>;
        this.userAccountStore.setUserAccountLoggedIn(
          <boolean>isLoggedInData.data,
        );

        const myTenantsData = response[2] as Q90ResponseData<TenantInterface[]>;
        const myTenantsObjs: Tenant[] = myTenantsData.data.map((obj) =>
          new Tenant().deserialize(<TenantInterface>obj),
        );
        this.userAccountStore.setUserAccountMyTenants(myTenantsObjs);

        const myContentChannelsData = response[3] as Q90ResponseData<
          ContentChannelInterface[]
        >;
        const myContentChannelsObjs: ContentChannel[] =
          myContentChannelsData.data.map((obj) =>
            new ContentChannel().deserialize(<ContentChannelInterface>obj),
          );
        this.userAccountStore.setUserAccountMyContentChannels(
          myContentChannelsObjs,
        );

        const myTeamsData = response[4] as Q90ResponseData<TeamInterface[]>;
        const myTeamsObjs: Team[] = myTeamsData.data.map((obj) =>
          new Team().deserialize(<TeamInterface>obj),
        );
        this.userAccountStore.setUserAccountMyTeams(myTeamsObjs);
      },
      error: (error) => {
        this.setQueryingPrimeableService(false);
        this.errorService.setError(error);
      },
    });
  }

  register(
    query: Partial<UserAccountInterface & PersonInterface>,
  ): Observable<Q90ResponseData<any>> {
    return this.http
      .post<Q90ResponseData<any>>(
        environment.apiEndpointRoot +
          environment.apiEndpointVersion +
          '/useraccount/register',
        {
          username: query.username,
          password: query.password,
          email: query.emailAddress,
          name: query.name,
          surname: query.surname,
          displayName: query.displayName,
          avatar: query.avatar,
          languageId: query.languageId,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  update(userAccount: Partial<UserAccount>) {
    return this.http
      .post<any>(
        `${
          environment.apiEndpointRoot + environment.apiEndpointVersion
        }/useraccount/user/update`,
        {
          languageId: userAccount.languageId,
          displayName: userAccount.displayName,
          isPublic:
            (userAccount.isPublic as unknown) === 'true' ||
            (userAccount.isPublic as unknown) === true
              ? true
              : false,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  activateUserAccount(activationKey: string) {
    return this.http
      .post<Q90ResponseData<any>>(
        `${
          environment.apiEndpointRoot + environment.apiEndpointVersion
        }/useraccount/activation/activate`,
        {
          activationKey: activationKey,
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  resendActivation(username: string) {
    return this.http
      .post<any>(
        `${
          environment.apiEndpointRoot + environment.apiEndpointVersion
        }/useraccount/activation/key/resend`,
        {
          username: username,
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  twofactorSetup() {
    return this.http
      .post<any>(
        `${
          environment.apiEndpointRoot + environment.apiEndpointVersion
        }/useraccount/authentication/twofactor/setup`,
        {},
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  twofactorSetupFinish(pinCode: string) {
    return this.http
      .post<any>(
        `${
          environment.apiEndpointRoot + environment.apiEndpointVersion
        }/useraccount/authentication/twofactor/setup/finish`,
        {
          pinCode: pinCode,
        },
        {
          // headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  twofactorDisable(pinCode: string) {
    return this.http
      .post<any>(
        `${
          environment.apiEndpointRoot + environment.apiEndpointVersion
        }/useraccount/authentication/twofactor/disable`,
        {
          pinCode: pinCode,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  twofactorRegenerate(pinCode: string) {
    return this.http
      .post<any>(
        `${
          environment.apiEndpointRoot + environment.apiEndpointVersion
        }/useraccount/authentication/twofactor/recoverycodes/regenerate`,
        {
          pinCode: pinCode,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  resetPasswordRequest(username: string) {
    return this.http
      .post<any>(
        `${
          environment.apiEndpointRoot + environment.apiEndpointVersion
        }/useraccount/password/reset/request`,
        {
          username: username,
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  resetPasswordComplete(resetKey: string, newPassword: string) {
    return this.http
      .post<any>(
        `${
          environment.apiEndpointRoot + environment.apiEndpointVersion
        }/useraccount/password/reset/complete`,
        {
          resetKey: resetKey,
          newPassword: newPassword,
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  passwordChange(newPassword: string) {
    return this.http
      .post<any>(
        `${
          environment.apiEndpointRoot + environment.apiEndpointVersion
        }/useraccount/password/change`,
        {
          newPassword: newPassword,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  uploadAvatar(formData: FormData) {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot}${environment.apiEndpointVersion}/useraccount/avatar/upload`,
        formData,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  removeAvatar() {
    return this.http
      .delete<any>(
        `${environment.apiEndpointRoot}${environment.apiEndpointVersion}/useraccount/avatar/remove`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  private isLoggedIn() {
    return this.http
      .get<Q90ResponseData<boolean>>(
        `${
          environment.apiEndpointRoot + environment.apiEndpointVersion
        }/useraccount/isloggedin`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  private userAccount() {
    return this.http
      .get<Q90ResponseData<UserAccountInterface>>(
        `${
          environment.apiEndpointRoot + environment.apiEndpointVersion
        }/useraccount`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.logout();
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  private myTenants() {
    return this.http
      .get<Q90ResponseData<TenantInterface[]>>(
        `${
          environment.apiEndpointRoot + environment.apiEndpointVersion
        }/useraccount/mytenants`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  fetchMyTenants(
    query: Q90EntitySearchQuery,
  ): Observable<Q90ResponseData<Q90SearchResult<TenantInterface[]>>> {
    return this.http
      .post<Q90ResponseData<Q90SearchResult<TenantInterface[]>>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/mytenants/search`,
        {
          ...query,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (!Q90Response.isSuccess(res)) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  private myContentChannels() {
    return this.http
      .get<Q90ResponseData<ContentChannelInterface[]>>(
        `${
          environment.apiEndpointRoot + environment.apiEndpointVersion
        }/useraccount/mycontentchannels`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  fetchMyContentChannels(
    query: Q90EntitySearchQuery,
  ): Observable<Q90ResponseData<Q90SearchResult<ContentChannelInterface[]>>> {
    return this.http
      .post<Q90ResponseData<Q90SearchResult<ContentChannelInterface[]>>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/mycontentchannels/search`,
        {
          ...query,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (!Q90Response.isSuccess(res)) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  private myTeams() {
    return this.http
      .get<Q90ResponseData<TenantInterface[]>>(
        `${
          environment.apiEndpointRoot + environment.apiEndpointVersion
        }/useraccount/myteams`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  fetchMyTeams(
    query: Q90EntitySearchQuery,
  ): Observable<Q90ResponseData<Q90SearchResult<TeamInterface[]>>> {
    return this.http
      .post<Q90ResponseData<Q90SearchResult<TeamInterface[]>>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/useraccount/myteams/search`,
        {
          ...query,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (!Q90Response.isSuccess(res)) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }
}
