import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map, catchError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthenticationStoreService } from '../../../authentication/stores/authentication-store.service';
import { Q90ResponseData } from '../../../shared/interfaces/q90-response';
import { Team, TeamInterface } from '../models/team';
import { OptionCollection } from '../../../shared/interfaces/option-collection';
import { Q90Response } from '../../../shared/models/q90-response';
import { ErrorService } from '../../../shared/services/error.service';
import { ContentChannel } from '../../content-channel/models/content-channel';
import { Tenant } from '../../tenant/models/tenant';

@Injectable({
  providedIn: 'root',
})
export class TeamServiceService {
  private authenticationStore = inject(AuthenticationStoreService);
  private http = inject(HttpClient);
  private errorService = inject(ErrorService);

  constructor() {}

  createTeam(
    query: Partial<Team>,
    tenant: Tenant | null,
    contentChannel: ContentChannel | null,
  ): Observable<Q90ResponseData<TeamInterface>> {
    const url = contentChannel?.id
      ? `/contentchannel/${contentChannel!.id}/teams/create`
      : `/organization/${tenant!.id}/teams/create`;
    return this.http
      .post<Q90ResponseData<TeamInterface>>(
        environment.apiEndpointRoot + environment.apiEndpointVersion + url,
        {
          ...query,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  fetchTeamName(teamId: string): Observable<Q90ResponseData<any>> {
    return this.http
      .get<Q90ResponseData<any>>(
        environment.apiEndpointRoot +
          environment.apiEndpointVersion +
          `/team/${teamId}/name`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  fetchTeamOrderBy(): Observable<
    Q90ResponseData<Record<string, string | number | boolean>>
  > {
    return this.http
      .get<Q90ResponseData<Record<string, string | number | boolean>>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion}/team/teams/search/orderby/list`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken(),
          ),
          responseType: 'json',
        },
      )
      .pipe(
        map((res) => {
          if (!Q90Response.isSuccess(res)) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        }),
      );
  }

  // updateTeam(query: Partial<TeamInterface>, tenantId: string): Observable<Q90ResponseData<TenantInterface>> {
  //   return this.http.post<Q90ResponseData<TenantInterface>>(environment.apiEndpointRoot + environment.apiEndpointVersion + `/tenant/${tenantId}/update`,
  //     {
  //       id: query.id,
  //       name: query.name,
  //     },
  //     {
  //       headers: new HttpHeaders().set(
  //         'Authorization',
  //         'Bearer ' + this.authenticationStore.getToken()
  //       ),
  //       responseType: 'json',
  //     }
  //   )
  //     .pipe(
  //       map((res) => {
  //         return res;
  //       }),
  //       catchError((err) => {
  //         throw err;
  //       })
  //     );

  // }
}
