<ng-container *ngIf="data$ | async as data">
  <ng-container *ngIf="data && data.messages">
    <div *ngIf="showClose" class="{{styleClass}} error-message {{level}} grid-layout grid-cols-2 left-heavy align-center">
      <p>{{data.messages.message}}</p>
      <p class="show-pointer" (click)="closeMessage($event)"><span class="mci-close"></span></p>
    </div>
    <div *ngIf="!showClose" class="{{styleClass}} error-message {{level}} grid-layout grid-cols-1 align-center">
      <p>{{data.messages.message}}</p>
    </div>
  </ng-container>
</ng-container>
