<ng-container [formGroup]="formGroup">
  <div
    class="form-control {{styleClass}} {{additionalStyleClasses}}"
    [ngClass]="{'hide-errors-hints':(!showErrorHints)}"
  >
    <label
      class="wrap-lines"
      for="{{id}}"
      [ngClass]="{'visually-hidden': !showLabel, 'disabled': isDisabled}"
    >{{label}}</label>
    <div class="slider-container">
      <div class="labels">
        <span class="label-left">{{firstStep}}</span>
        <span class="label-right">{{lastStep}}</span>
      </div>
      <input
        id="slider"
        type="range"
        min="0"
        max="10"
        [formControl]="control | getAsFormControl"
      >
    </div>
    <div
      class="submit-warning"
      [ngClass]="{'invalid-after-submit': submitted && control.invalid}"
    ><span class='mci-warning'></span></div>
  </div>
  <div
    class="form-errors {{styleClass}}"
    *ngIf="showErrorHints && control.invalid && (submitted || control.touched)"
  >
    <p>{{getErrorMessage()}}</p>
  </div>
</ng-container>