<ng-container *ngIf="navigationSecondarySubnavData$ | async as navigationSecondarySubnavData">
  <core-navigation-sidebar-content [snapshot]="snapshot">
    <nav [ngClass]="navigationSecondarySubnavData.theme.class">
      <ul>
        <li
          *ngIf="navigationSecondarySubnavData.userAccountLoggedIn"
          class="breadcrumb"
        >
          <a [routerLink]="'/my-groups'">
            <span class="mci-arrowhead-left"></span>
            <span class="text">My Groups</span>
          </a>
        </li>
        <li class="entity-label">
          <span> {{ currentTenantIdStore.getTenantName() }} </span>(organization)
        </li>
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button
            type="button"
            (click)="toggle(appMenus.TenantsGeneralMenu)"
          >
            <span class="nav-icon mci-setting-solid"></span>
            <span class="feature-label">General</span>
            <span
              *ngIf="navigationSecondarySubnavData.appMenusState[appMenus.TenantsGeneralMenu]"
              class="mci-arrowhead-up-solid"
            ></span>
            <span
              *ngIf="!navigationSecondarySubnavData.appMenusState[appMenus.TenantsGeneralMenu]"
              class="mci-arrowhead-down-solid"
            ></span>
          </button>
          <ul *ngIf="navigationSecondarySubnavData.appMenusState[appMenus.TenantsGeneralMenu]">
            <li>
              <a
                [routerLink]="[baseRoute, 'information']"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Information
              </a>
            </li>
            <li>
              <a
                [routerLink]="[baseRoute, 'members']"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Members
              </a>
            </li>
            <li>
              <a
                [routerLink]="[baseRoute, 'style']"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Colors
              </a>
            </li>
            <li>
              <a
                [routerLink]="[baseRoute, 'contact']"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Contact
              </a>
            </li>
            <li>
              <a
                [routerLink]="[baseRoute, 'timezones']"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Timezones
              </a>
            </li>
            <li>
              <a
                [routerLink]="[baseRoute, 'languages']"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Languages
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </core-navigation-sidebar-content>
</ng-container>