import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from '../../environments/environment';

// Check if module is activated in the environment file
export const moduleGuard: CanActivateFn = (route, state) => {
  // Get the module name based on the first part of the url
  const moduleName = 'use_' + state.url.split('/')[1].replace('-', '');
  console.log(moduleName);
  // Initial value
  let moduleActive = false;

  // Loop through the environment variables
  Object.keys(environment).forEach((elm, index) => {
    // Name compare
    if (elm === moduleName)
      // Set module active based on variable
      moduleActive = Object.values(environment)[index] === true;
  });

  // Return or redirect to page not found
  return moduleActive
    ? moduleActive
    : inject(Router).createUrlTree(['/page-not-found']);
};
